import global from './global.json';
import validation from './validation.json';
import footer from './footer.json';
import header from './header.json';
import table from './table.json';
import home from './home.json';
import policy from './policy.json';
import uploader from './uploader.json';
import newPolicy from './new_policy.json';
import profile from './profile.json';
import chat from './chat.json';
import errors from './errors.json';
import claim from './claim.json';
import help from './help.json';
import meta from './meta.json';

export default {
  ...global,
  ...validation,
  ...footer,
  ...header,
  ...table,
  ...home,
  ...policy,
  ...uploader,
  ...newPolicy,
  ...profile,
  ...chat,
  ...errors,
  ...claim,
  ...help,
  ...meta,
};
